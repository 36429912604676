/*
 * @Date: 2020-12-28 17:44:27
 * @LastEditTime: 2021-01-07 18:00:03
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: main.js
 * @FilePath: \portal\src\main.js
 */

import Vue from 'vue'
import { Button as elButton, Dialog as elDialog, Form as elForm, FormItem as elFormItem, Input as elInput , Message} from 'element-ui';
import { Button as VButton, Form as VForm, Field as VField, CellGroup as VCellGroup } from 'vant';
import App from './App.vue'
import router from '@/router/index'
import "@/components/index";
import 'element-ui/lib/theme-chalk/index.css';

import "@/assets/css/main.css"
import "@/assets/js/rem"

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload,{}) 

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'YdSCL3hPGBlwdpzGVIGtnIZSUpMpmcjh'
})

Vue.use(elButton).use(elDialog).use(elForm).use(elFormItem).use(elInput)
Vue.prototype.$message = Message;
Vue.use(VButton).use(VForm).use(VField).use(VCellGroup)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
