<!--
 * @Date: 2020-12-29 11:24:08
 * @LastEditTime: 2022-03-24 19:16:21
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: 底部
 * @FilePath: \portal\src\components\comp\compFooter.vue
-->
<template>
  <div class="compFooter">
    <footer class="footer-box">
      <div class="bg_box">
        <div class="bg_title">立即体验，进一步了解享库全方位解决方案</div>
        <div class="tel">联系电话：4000228636</div>
        <!-- <div class="bg_btn">注册体验</div> -->
        <div class="bg_btn"  @click="toPage">了解更多</div>
      </div>
      <div class="fb_box">
        <div class="code_box_list flex align">
          <div class="code_box">
            <img src="https://cdn2.xxkucun.com/portal/home/iOS.png" alt="">
            <div class="text">IOS 版</div>
          </div>
          <div class="code_box">
            <img src="https://cdn2.xxkucun.com/portal/footer/code.png" alt="">
            <div class="text">享库公众号</div>
          </div>
          <div class="code_box">
            <img src="../../assets/img/common/Android.png" alt="">
            <div class="text">Android 版</div>
          </div>
        </div>
        

        <div class="copyright_text">
          <div class="text-14">享库-本地生活数字化营销服务平台</div>
          <div class="text-12">
            Copyright © 2019-2022 版权所有 上海享评信息科技有限公司
            备案序号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color:#fff;" >{{beian.number}}</a> 
          </div>
          <div class="text-12" v-if="beian.iscode">
            <img src="../../assets/img/badge.png" style="width:20px;" alt="">
            <span>沪公网安备 <a :href="`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${beian.code}`" target="_blank" style="color:#fff;">{{beian.code}}号</a></span>
          </div>
        </div>
      </div>
      

    </footer>

    <div class="btn-list">
      <div class="btn"
        v-for="(v, i) in btn_list"
        :key="v.name"
        @mouseover="qrIndex = i"
        @mouseout="qrIndex = null">
        <div class="qr-box" v-show="qrIndex == i">
          <div class="jio"></div>
          <img :src="v.url" :alt="v.alt" />
        </div>
        <img :class="`icon-top-${i}`" :src="v.icon" :alt="v.alt" />
        <!-- <span>{{ v.name }}</span> -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CompFooter',
  props: {
    flex: {
      type: Boolean,
      default: false,
    },
    flex_bgColor: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      btn_list: [
        {
          url: 'https://cdn2.xxkucun.com/portal/home/iOS.png',
          alt: 'IOS',
          name: 'IOS版',
          icon: 'https://cdn2.xxkucun.com/portal/home/download_ios.png',
        },
        {
          url: require('../../assets/img/common/Android.png'),
          alt: 'Android',
          name: 'Android 版',
          icon: 'https://cdn2.xxkucun.com/portal/home/download_android.png',
          },
        {
          url: require('../../assets/img/common/WeiXin.jpg'), //'https://cdn2.xxkucun.com/portal/home/WeiXin.jpg',
          alt: 'WeiXin',
          name: '微信公众号',
          icon: 'https://cdn2.xxkucun.com/portal/home/download_weixin.png',
        },
      ],
      qrIndex: null,
      beian:{
        url:'xkcun.com',
        number:'沪ICP备19014803号-2',
        iscode:true
      }
    }
  },
  created(){
    this.urlFun()
  },
  methods:{
    toPage(){
      this.$router.push('my')
    },
    urlFun(){
      const arr = [
        {url:'xkc01.com',number:'沪ICP备19014803号-5',iscode:true, code:'31010402009733'},
        {url:'xkcun.vip',number:'沪ICP备19014803号-6',iscode:true, code:'31010402009732'},
        {url:'xkclife.com',number:'沪ICP备19014803号-7',iscode:true, code:'31010402009734'},
        {url:'xkc01.cn',number:'沪ICP备19014803号-8',iscode:true, code:'31010402009731'},
        {url:'xkcun.com',number:'沪ICP备19014803号-2',iscode:true, code: '31010402009210'},
        {url:'localhost:8080',number:'沪ICP备19014803号-2',iscode:true, code: '31010402009210'},
      ]
      let obj = {}
      arr.forEach(e=>{
        if(location.host.indexOf(e.url)!==-1){
          obj = e
        }
      })
      this.beian = obj
    }
  }
}
</script>
<style lang="less" scoped>
.footer-flex {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}
.compFooter {
  width: 100%;
  // height: 1.18rem;
  box-sizing: border-box;
  // padding-top: 0.4rem;
  // padding-bottom: 0.32rem;

  .footer-box {
    width: 100%;
    .bg_box{
      box-sizing: border-box;
      padding:65px;
      width: 100%;
      height: 306px;
      background-image: url('https://cdn2.xxkucun.com/portal/footer/footer_bg.png');
      -moz-background-size:100% 100%; 
      background-size:100% 100%;
      .bg_title{
        font-family: MicrosoftYaHei-Bold;
        font-size: 40px;
        font-weight: 0;
        line-height: 60px;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        margin: 0px auto;
      }
      .tel {
        font-family: MicrosoftYaHei-Bold;
        font-size: 24px;
        font-weight: 0;
        text-align: center;
        color: #FFFFFF;
        margin: 0px auto;
      }
      .bg_btn{
        cursor: pointer;
        margin: 0 auto;
        margin-top:24px;
        width: 0.8rem;
        height: 0.3rem;
        display: flex;
        flex-direction: column;
        padding: 12px 32px;
        border-radius: 4px;
        background: #FFFFFF;
        box-shadow: 0px 0.01px 0.02px 0px rgba(255, 22, 58, 0.25);
        font-family: MicrosoftYaHei-Bold;
        font-size: 20px;
        font-weight: bold;
        line-height: 0.3rem;
        text-align: center;
        letter-spacing: 0em;
        color: #FF163A;
      }
    }
    .fb_box{
      position: relative;
      width: 100%;
      height: 490px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 80px 0px 40px 0px;
      background: #201D1D;
      .code_box{
        width: 200px;
        height: 200px;
        text-align: center;
        margin: 0 auto;
        >img{
          width: 160px;
          height: 160px;
        }
        .text{
          margin-top: 16px;
          font-family: MicrosoftYaHei;
          font-size: 18px;
          font-weight: 0;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0em;
          color: rgba(255, 255, 255, 0.45);
        }
      }
      .copyright_text{
        margin-top:110px ;
        color: #fff;
      }
      
    }
  }
  .btn-list {
    // width: 100%;
    // margin-top: 0.72rem;
    position: fixed;
    right: 40px;
    bottom: 120px;
    .btn {
      // justify-content: center;
      width: 0.4rem;
      height: 0.4rem;
      background: #fff;
      text-align: center;
      border-radius: 20px;
      line-height: 0.4rem;
      color: rgba(0, 0, 0, 0.85);
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      position: relative;
      margin-bottom: 40px;
      > img {
        width: 0.2rem;
        height: 0.2rem;
      }
      .icon-top-2 {
        width: 0.24rem;
        height: 0.24rem;
      }
      .qr-box {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: -0.8rem;
        left: -2.2rem;
        background: #fff;
        border-radius: 0.2rem;
        box-sizing: border-box;
        padding: 0.2rem;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        .jio {
          width: 0.2rem;
          height: 0.2rem;
          position: absolute;
          bottom: 0.9rem;
          right: -0.1rem;
          background: #fff;
          transform: rotate(-45deg);
          -ms-transform: rotate(-45deg); /* Internet Explorer */
          -moz-transform: rotate(-45deg); /* Firefox */
          -webkit-transform: rotate(-45deg); /* Safari 和 Chrome */
          -o-transform: rotate(-45deg); /* Opera */
          box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1);
        }
        > img {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }
}
</style>
