import axios from 'axios'
import qs from 'qs'

const pendingRequest = new Map()

/**
 * 生成每个请求唯一的键
 * @param {Object} config
 * @returns string
 */
function generateReqKey(config = {}) {
  const { method, url, params, data } = config
  return [method, url, qs.stringify(params), qs.stringify(data)].join('&')
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {Object} config
 */
export function addPendingRequest(config = {}) {
  const requestKey = generateReqKey(config)
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken(cancel => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel)
      }
    })
}

/**
 * 删除重复的请求
 * @param {*} config
 */
export function removePendingRequest(config = {}) {
  const requestKey = generateReqKey(config)
  if (pendingRequest.has(requestKey)) {
    const cancelToken = pendingRequest.get(requestKey)
    cancelToken(requestKey)
    pendingRequest.delete(requestKey)
  }
}
