/*
 * @Date: 2021-01-12 12:10:48
 * @LastEditTime: 2021-02-01 13:53:12
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: 
 * @FilePath: \portal\src\router\routes\mobile.js
 */
export default [{
    name:'mobile_home',
    path: "/mobile_home",
    meta: {
        title: "首页"
    },
    component: () => import( /* webpackChunkName: "mobile" */ "@/views/mobile_home/index.vue")
}, {
    name:'mobile_community',
    path: "/mobile_community",
    meta: {
        title: "社区精选"
    },
    component: () => import( /* webpackChunkName: "mobile" */ "@/views/mobile_community/index.vue")
}, {
    name:'mobile_my',
    path: "/mobile_my",
    meta: {
        title: "关于我们"
    },
    component: () => import( /* webpackChunkName: "mobile" */ "@/views/mobile_my/index.vue")
}, {
    name:'mobile_cooperation',
    path: "/mobile_cooperation",
    meta: {
        title: "合作伙伴"
    },
    component: () => import( /* webpackChunkName: "mobile" */ "@/views/mobile_cooperation/index.vue")
}, {
    name:'mobile_contact',
    path: "/mobile_contact",
    meta: {
        title: "欢迎合作"
    },
    component: () => import( /* webpackChunkName: "mobile" */ "@/views/mobile_contact/index.vue")
}]