<!--
 * @Date: 2021-01-12 13:42:33
 * @LastEditTime: 2022-03-24 19:49:53
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: 移动端顶部
 * @FilePath: \portal\src\components\comp\compMobileHead.vue
-->
<template>
  <div class="compHead" :class="bgColor ? 'bgColor' : ''">
    <header>
      <div class="head-box flex between align">
        <div class="img" @click="clickLogo">
          <img src="../../assets/img/common/logo_2.png" alt="享库生活" />
        </div>
        <nav class="nav" @click="showNavModel">
          <img src="https://cdn2.xxkucun.com/portal/mobile_home/nav_1.png" alt="nav" />
        </nav>
      </div>
    </header>
    <div class="nav_model" @click="cancelModel" v-show="navModel">
      <div class="box" @click.stop >
          <div class="header flex between align">
            <div class="img" @click="clickLogo">
              <img src="../../assets/img/common/logo_2.png" alt="享库生活" />
            </div>
            <div class="cancel text-48" @click="cancelModel" :style="`${logoColor?'color:#333;':''}`">×</div>
          </div>
          <div class="nav_list">
            <div v-for="(e , i) in navList" :key='i' @click="clickNavTab(i,e.path)">
              <div class="item text-32 flex align between" >
                <span>{{e.name}}</span>
                <img v-if="e.right_icon" :src="e.right_icon" :style="show_introduction_box?`transform:rotate(180deg);`:''" alt="">
              </div>
              <div class="introduction_box flex between wrap" @click.stop v-if="i==1&&show_introduction_box">
                <div class="system_list_box" v-for="(item,index) in system_list" :key="index">
                  <div class="system_list_item" v-for="ev in item" :key="ev.name" :class="ev.isTitle?'system_title':''">{{ev.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_box">
            <!-- <div class="btn_text">免费试用</div> -->
            <div class="btn_login" @click="toPage">欢迎合作</div>
          </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompMobileHead',
  props: {
    logoColor: {
      type: Boolean,
      default: false,
    },
    navIndex: {
      type: Number,
      default: 0,
    },
    bgColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      navList: [
        { name: '首页', path: 'mobile_home' },
        { name: '产品介绍', path: '', right_icon:require('../../assets/img/head/mobile_head_jiantou.png')  },
        { name: '关于我们', path: 'mobile_my' },
        { name: '合作伙伴', path: 'mobile_cooperation' },
      ],
      system_list: [
        [
          {name:'享库管家Saas系统',path:'',isTitle:true},
          {name:'POI管理',path:''},
          {name:'团购电商管理',path:''},
          {name:'享库外卖',path:''},
          {name:'在线买单',path:''},
          {name:'数据分析',path:''},
        ],
        [
          {name:'惠本地分销系统',path:'',isTitle:true},
          {name:'电商小程序',path:''},
          {name:'串货云市场',path:''},
          {name:'智能分账',path:''},
        ],
        [
          {name:'享库探店团系统',path:'',isTitle:true},
          {name:'达人大数据匹配',path:''},
          {name:'自动视频剪辑',path:''},
          {name:'达人自动撮合匹配',path:''},
          {name:'账号代运营',path:''},
        ],
        [
          {name:'享来客私域系统',path:'',isTitle:true},
          {name:'智能会员管理',path:''},
          {name:'智能社群管理',path:''},
          {name:'营销活动插件',path:''},
        ],
        
      ],
      show_introduction_box: false,
      tabIndex: 0,
      navModel: false,
    }
  },
  mounted() {
    this.tabIndex = this.navIndex
  },
  methods: {
    clickLogo() {
      this.navModel = false
      if ('mobile_home' == this.$route.name) return ''
      this.$router.push('mobile_home')
    },
    showNavModel() {
      this.navModel = true
    },
    cancelModel() {
      this.navModel = false
    },
    clickNavTab(i, e) {
      this.tabIndex = i
      if(i===1){
        this.show_introduction_box = !this.show_introduction_box
      }else {
        this.show_introduction_box = false
      }
      if(!e)return '';
      if (e == this.$route.name) return this.navModel = false
      this.$router.push(e)
    },
    toPage(){
      this.$router.push("mobile_contact")
    }
  },
}
</script>
<style lang="less" scoped>
.compHead {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 96;
  .head-box {
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 0.16rem;
    padding-bottom: 0.16rem;
    .img {
      width: 2rem;
      height: 0.64rem;
      overflow: hidden;
      cursor: pointer;
      > img {
        width: 100%;
      }
    }
    .nav {
      width: 0.4rem;
      height: 0.4rem;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav_model {
    width: 100%;
    max-height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);
    .box{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 70%;
      height: 100%;
      background: #fff;
      color: #333;
      box-sizing: border-box;
    }
    .header{
      width: 90%;
      margin: 0 auto;
      box-sizing: border-box;
      padding-top: 0.16rem;
      padding-bottom: 0.16rem;
      .img {
        width: 2rem;
        height: 0.64rem;
        overflow: hidden;
        cursor: pointer;
        > img {
          width: 100%;
        }
      }
      .cancel {
        width: 0.4rem;
        height: 0.4rem;;
        cursor: pointer;
      }
    }
    .nav_list{
      box-sizing: border-box;
      height: 800px;
      padding-bottom: 450px;
      overflow: hidden;
      overflow-y: auto;
      .item{
        // margin-bottom: 1rem;
        color: #333;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        text-align: left;
        line-height: 65px;
        padding: 0 20px;
        >img{
          width:24px;
        }
      }
      .introduction_box{
        transition: all 3s;
        width: 100%;
        display: flex;
        background: #F5F5F5;
        .system_list_box{
          padding: 0 20px;
          z-index: 999;
          text-align: left;
          width: 100%;
          .system_list_item{
            line-height: 40px;
            cursor: pointer;
            font-family: MicrosoftYaHei;
            font-size: 0.24rem;
            font-weight: 0;
            letter-spacing: 0em;
            color: rgba(0, 0, 0, 0.85);
          }
          .system_title{
            margin-top: 0;
            font-family: MicrosoftYaHei-Bold;
            font-size: 0.28rem;
            font-weight: bold;
            line-height: 40px;
            letter-spacing: 0em;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
    .btn_box{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      background: #fff;
      z-index: 999;
      .btn_text{
        color: #FF163A;
        letter-spacing: 0px;
        cursor: pointer;
      }
      .btn_login{
        margin: 20px auto;
        cursor: pointer;
        // width: 68px;
        // height: 32px;
        padding: 10px 20px;
        border-radius: 4px;
        background: #FF163A;
        box-shadow: 0px 0.01px 0.02px 0px rgba(255, 22, 58, 0.25);
        color: #fff;
        box-sizing: border-box;
      }
    }
  }
}
.compHead.bgColor {
  background: #fff;
  border-bottom: 0.02rem solid rgba(0, 0, 0, 0.09);
}
</style>