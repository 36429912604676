/*
 * @Date: 2020-12-29 12:17:10
 * @LastEditTime: 2020-12-29 12:17:30
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: 公共组件
 * @FilePath: \xkc_website\src\components\index.js
 */
import vue from 'vue'
const files = require.context('./comp', false, /\.vue$/)
files.keys().forEach(module => {
    const reg = /^\.\/(.*)\.vue/ig;
    vue.component(module.replace(reg, '$1'), files(module).default)
})
