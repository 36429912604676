<!--
 * @Date: 2021-01-12 13:43:36
 * @LastEditTime: 2022-03-18 12:21:46
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: 移动端底部
 * @FilePath: \portal\src\components\comp\compMobileFooter.vue
-->
<template>
  <div
    class="compFooter"
    :class="
      flex
        ? 'footer-flex'
        : flex_bgColor
        ? 'footer-flex bg-active'
        : 'bg-active'">
    <!-- <footer class="footer-box">
      <div class="text-24">享库-本地生活数字化营销服务平台</div>
      <div class="text-20">
        Copyright © 2019-2021 版权所有 备案序号：<a
          href="https://beian.miit.gov.cn/"
          :style="flex ? 'color:#fff;' : ''"
          >沪ICP备19014803号-2</a
        >
      </div>
      <div class="text-20">
        <img src="../../assets/img/badge.png" style="width:20px;" alt="">
        <span>沪公网安备 <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009210" :style="flex ? 'color:#fff;' : ''">31010402009210号</a></span>
      </div>
    </footer> -->
    <footer class="footer-box">
      <div class="bg_box">
        <div class="bg_title">立即体验，进一步了解享库全方位解决方案</div>
        <!-- <div class="bg_btn">注册体验</div> -->
        <div class="bg_btn" @click="toPage">了解更多</div>
      </div>
      <div class="fb_box">
        <div class="code_box">
          <img src="https://cdn2.xxkucun.com/portal/footer/code.png" alt="">
          <div class="text">享库公众号</div>
        </div>
        <div class="copyright_text">
          <div class="text-28">享库-本地生活数字化营销服务平台</div>
          <div class="text-24">
            Copyright © 2019-2022 版权所有 上海享评信息科技有限公司 <br/>
            备案序号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color:#fff;" >{{beian.number}}</a> 
          </div>
          <div class="text-24" v-if="beian.iscode">
            <img src="../../assets/img/badge.png" style="width:20px;" alt="">
            <span>沪公网安备 <a :href="`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${beian.code}`" target="_blank" style="color:#fff;">{{beian.code}}号</a></span>
          </div>
        </div>
      </div>
      

    </footer>
    <div class="downloadAppAndWeiXin_box" :class="scrollTop>=maxScrollTop?'active':''" v-if="maxScrollTop">
      <div class="download_btn">
        <comp-download-btn :isColor='true' right="0" bottom="0" />
      </div>
      <comp-wx-btn right="0.24" bottom="0" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompMobileFooter',
  props: {
    flex: {
      type: Boolean,
      default: false,
    },
    flex_bgColor: {
      type: Boolean,
      default: false,
    },
    scrollTop: {
      type: Number,
      default: 1,
    },
    maxScrollTop: {
      type: Number,
      default: 0,
    },
  },
  data(){
    return {
      beian:{
        url:'xkcun.com',
        number:'沪ICP备19014803号-2',
        iscode:true
      }
    }
  },
  created(){
    this.urlFun()
  },
  methods:{
    toPage(){
      this.$router.push('mobile_my')
    },
    urlFun(){
      const arr = [
        {url:'xkc01.com',number:'沪ICP备19014803号-5',iscode:true, code:'31010402009733'},
        {url:'xkcun.vip',number:'沪ICP备19014803号-6',iscode:true, code:'31010402009732'},
        {url:'xkclife.com',number:'沪ICP备19014803号-7',iscode:true, code:'31010402009734'},
        {url:'xkc01.cn',number:'沪ICP备19014803号-8',iscode:true, code:'31010402009731'},
        {url:'xkcun.com',number:'沪ICP备19014803号-2',iscode:true, code: '31010402009210'},
        {url:'localhost:8081',number:'沪ICP备19014803号-2',iscode:true, code: '31010402009210'},
      ]
      let obj = {}
      arr.forEach(e=>{
        if(location.host.indexOf(e.url)!==-1){
          obj = e
        }
      })
      this.beian = obj
    }
  }
}
</script>
<style lang="less" scoped>
.footer-flex {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}
.compFooter {
  width: 100%;
  min-height: 1.12rem;
  box-sizing: border-box;
  // padding-top: 0.24rem;
  padding-bottom: 0.22rem;
  background: rgba(0, 0, 0, 0.65);
 .footer-box {
    width: 100%;
    .bg_box{
      box-sizing: border-box;
      padding:65px;
      width: 100%;
      height: 306px;
      background-image: url('https://cdn2.xxkucun.com/portal/footer/footer_bg.png');
      -moz-background-size:100% 100%; 
      background-size:100% 100%;
      .bg_title{
        font-family: MicrosoftYaHei-Bold;
        font-size: 24px;
        font-weight: 0;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        margin: 0px auto;
      }
      .bg_btn{
        cursor: pointer;
        margin: 0 auto;
        margin-top:40px;
        width: 128px;
        // height: 48px;
        // display: flex;
        // flex-direction: column;
        padding: 12px 32px;
        border-radius: 4px;
        background: #FFFFFF;
        box-shadow: 0px 0.01px 0.02px 0px rgba(255, 22, 58, 0.25);
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        font-weight: 0;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0em;
        color: #FF163A;
      }
    }
    .fb_box{
      position: relative;
      width: 100%;
      height: 490px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 80px 0px 40px 0px;
      background: #201D1D;
      .code_box{
        width: 200px;
        height: 200px;
        text-align: center;
        margin: 0 auto;
        >img{
          width: 160px;
          height: 160px;
        }
        .text{
          margin-top: 16px;
          font-family: MicrosoftYaHei;
          font-size: 18px;
          font-weight: 0;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0em;
          color: rgba(255, 255, 255, 0.45);
        }
      }
      .copyright_text{
        margin-top:110px ;
        color: #fff;
      }
    }
  }
  .downloadAppAndWeiXin_box{
      height: 0.8rem;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0.5rem;
      .download_btn{
        width: 3.2rem;
        height: 0.8rem;
        margin: 0 auto;
        position: relative;
      }
    }
    .downloadAppAndWeiXin_box.active{
      margin-top: 0.4rem;
      position: relative;
      left: 0;
      right: 0;
      bottom: 0;
    }
}
.compFooter.bg-active {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.85);
}
</style>
