<!--
 * @Date: 2021-01-12 15:39:11
 * @LastEditTime: 2021-01-14 11:38:42
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: 微信公众号组件
 * @FilePath: \portal\src\components\comp\compWxBtn.vue
-->
<template>
  <div class="compWxBtn_box flex align" :style="`right:${right}rem;bottom:${bottom}rem;z-index:${wxModel?'999':'93'};`" @click="wxModel=true">
    <img src="https://cdn2.xxkucun.com/portal/mobile_home/home_wx.png" alt="微信公众号" />
    <div class="wx_model" v-show="wxModel" @click.stop="wxModel=false" :style="`z-index:${wxModel?'999':'93'};`">
      <div class="code_box" @click.stop>
        <div class="code_title text-32">长按保存二维码</div>
        <div class="code">
          <img src="../../assets/img/common/WeiXin.jpg" alt="微信公众号二维码">
        </div>
        <div class="code_desc text-28">微信扫一扫进入公众号</div>
      </div>
      <div class="code_xxx">
        <img src="../../assets/img/common/xxx.png" alt="关闭微信公众号二维码">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompWxBtn',
  props: {
      right:{
        type:String,
        default: ''
      },
      bottom:{
        type:String,
        default: ''
      },
  },
  data() {
    return {
      wxModel: false
    }
  },
  mounted() {},
  methods: {},
}
</script>
<style lang="less" scoped>
.compWxBtn_box {
  width: 0.8rem;
  height: 0.8rem;
  background: linear-gradient(270deg, #ff163a 0%, #ff1776 100%);
  border-radius: 0.4rem;
  justify-content: center;
  position: absolute;
  z-index: 98;
  >img{
      width: 0.4rem;
      height: 0.4rem;
  }
  .wx_model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: 99;
    .code_box{
      width: 5.56rem;
      height: 6.72rem;
      border: 0.02rem solid #979797;
      border-radius: 0.24rem;
      background: #fff;
      margin: 4rem auto;
      margin-bottom: 0.8rem;
      box-sizing: border-box;
      padding: 0.4rem 0.48rem;
      .code_title{
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 0.4rem;
      }
      .code{
        width: 4.6rem;
        height: 4.6rem;
        margin: 0.24rem auto;
        >img{
          width: 100%;
          height: 100%;
        }
      }
      .code_desc{
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 0.4rem;
      }
    }
    .code_xxx{
      width: 0.48rem;
      height: 0.48rem;
      margin: 0 auto;
      >img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>