import axios from 'axios'
import { Message } from 'element-ui'
// import router from '@/router'
import { removePendingRequest, addPendingRequest } from './pending'

// const toLogin = () => {
//   router.push({
//     path: '/login',
//     query: {
//       redirect: router.currentRoute.fullPath,
//     },
//   })
// }

const request = (axiosConfig = {}, customOptions = {}) => {
  const options = {
    repeatRequestCancel: true, // 是否开启取消重复请求设置, 默认为true
    showErrorMessage: true, // 是否开启code不为0时的信息提示, 默认为true
    ...customOptions,
  }

  // 50001: 'token过期'
  // 50002: 'token不能为空'
  // 50003: 'token错误'
  // const tokenErrCode = [50001, 50002, 50003]

  const service = axios.create({
    // baseURL: options.mock ? process.env.VUE_APP_MOCK_API : process.env.VUE_APP_BASE_API,
    timeout: 10000,
  })

  service.interceptors.request.use(
    config => {
      // if (store.state.user.token) {
      //   config.headers.token = store.state.user.token
      // }
      // config.headers.token = localStorage.getItem('token')
      if (options.repeatRequestCancel) {
        removePendingRequest(config) // 检查是否存在重复请求，若存在则取消已发的请求
        addPendingRequest(config) // 把当前请求信息添加到pendingRequest对象中
      }
      return config
    },
    error => Promise.reject(error)
  )

  service.interceptors.response.use(
    response => {
      if (options.repeatRequestCancel) removePendingRequest(response.config) // 从pendingRequest对象中移除请求

      const res = response?.data ?? {}

      // if (res.code !== 0) {

      //   if (options.showErrorMessage) {
      //     Message({
      //       message: res.msg || '服务端失败',
      //       type: 'error',
      //       duration: 3000,
      //     })
      //   }
      //   return Promise.reject(res)
      // }

      return res
    },
    error => {
      if (options.repeatRequestCancel) {
        removePendingRequest(error.config) // 从pendingRequest对象中移除请求
        if (axios.isCancel(error)) console.warn(`已取消的重复请求：${error.message}`)
      } else {
        // 添加异常处理
        Message({
          message: '网络又开小差了,请稍后刷新重试!',
          duration: 3000,
        })
      }
      return Promise.reject(error)
    }
  )

  return service(axiosConfig)
}

export const get = (url, params = {}, options = {}, timeout = 10000, headers = {}) =>
  request(
    {
      method: 'get',
      url,
      params,
      timeout,
      headers,
    },
    options
  )

export const post = (url, params = {}, options = {}, timeout = 10000, headers = {}) =>
  request(
    {
      method: 'post',
      url,
      data: params,
      timeout,
      headers,
    },
    options
  )

export default request
