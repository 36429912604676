<!--
 * @Date: 2020-12-28 17:44:27
 * @LastEditTime: 2022-03-16 15:16:08
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: app.vue
 * @FilePath: \portal\src\App.vue
-->
<template>
  <div id="app">
    <!-- <transition name="move" mode="out-in"> -->
        <router-view></router-view>
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  created() {
    console.log("object", navigator.userAgent)
    if(/mobile/i.test(navigator.userAgent)){
      this.$router.push('mobile_home')
    } else {
      this.$router.push('/')
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  font-size: 14px;
}
</style>
