/*
 * @Date: 2020-12-29 11:10:40
 * @LastEditTime: 2021-01-08 10:22:05
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: 路由
 * @FilePath: \portal\src\router\index.js
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
let routerArr = []
const files = require.context('./routes', false, /\.js$/);
files.keys().forEach(v => {
  routerArr.push(...files(v).default)
})

const router = new Router({
  // mode:'history',
  routes: routerArr
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  // document.title=to.meta.title
  next();
});

export default router;