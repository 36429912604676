<!--
 * @Date: 2021-01-12 17:17:58
 * @LastEditTime: 2021-01-14 13:52:11
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: 下载按钮
 * @FilePath: \portal\src\components\comp\compDownloadBtn.vue
-->
<template>
  <div
    class="compDownloadBtn_box flex align"
    :class="isColor ? 'active' : ''"
    :style="`right:${right}rem;bottom:${bottom}rem;`"
    @click="download"
  >
    <div class="img">
      <img src="https://cdn2.xxkucun.com/portal/mobile_home/home_phone.png" alt="icon" />
    </div>
    <div>下载APP</div>
  </div>
</template>

<script>
export default {
  name: 'CompDownloadBtn',
  props: {
    isColor: {
      type: Boolean,
      default: false,
    },
    right: {
      type: String,
      default: '',
    },
    bottom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
      download(){
        /* ios和安卓跳转 */
        var u = navigator.userAgent;
        // var ua = navigator.userAgent.toLowerCase();
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios
        if (isIOS) {
            window.location.href = 'https://apps.apple.com/cn/app/%E4%BA%AB%E5%BA%93%E7%94%9F%E6%B4%BB-%E5%90%83%E5%96%9D%E7%8E%A9%E4%B9%90%E7%88%86%E6%AC%BE%E6%8A%A2%E8%B4%AD/id1466206649'
        } else if (isAndroid) {
            window.location.href = 'https://xiangkucun-bucket.oss-cn-shanghai.aliyuncs.com/common/down/app-release.apk'
        } else {
        // PC端跳转到android下载地址
            window.location.href = 'https://xiangkucun-bucket.oss-cn-shanghai.aliyuncs.com/common/down/app-release.apk'
        }
      }
  },
}
</script>
<style lang="less" scoped>
.compDownloadBtn_box {
  position: absolute;
  width: 3.2rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  border: 0.02rem solid #ffffff;
  justify-content: center;
  color: #fff;
  line-height: 0.8rem;
  .img {
    width: 0.4rem;
    height: 0.4rem;
    line-height: 0.4rem;
    // overflow: hidden;
    margin-right: 0.1rem;
    > img {
      width: 100%;
      height: 100%;
      margin-top: -0.05rem;
    }
  }
}
.compDownloadBtn_box.active {
  background: linear-gradient(270deg, #ff163a 0%, #ff1776 100%);
  border: 0.02rem solid #ff1776;
}
</style>
