<!--
 * @Date: 2020-12-29 11:23:53
 * @LastEditTime: 2022-03-24 19:30:46
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: 头部
 * @FilePath: \portal\src\components\comp\compHead.vue
-->
<template>
  <div class="compHead" :class="bgColor ? 'bgColor' : ''">
    <header>
      <div class="head-box flex between align">
        <div class="flex align">
          <div class="img" @click="clickLogo">
            <img src="../../assets/img/common/logo_2.png" alt="享库生活"  />
          </div>
          <nav class="nav flex align text-16">
            <div
              class="item"
              v-for="(item, index) in navList"
              :key="index"
              @click="clickNavTab(index,item.path)"
              @mouseover="index==1?show_introduction_box=true:show_introduction_box=false"
              @mouseleave="show_introduction_box=false"
            >
              <div class="flex align">
                <span>{{ item.name }}</span>
                <img v-if="item.right_icon" :src="item.right_icon" :style="show_introduction_box?`transform:rotate(180deg);`:''" alt="">
              </div>
              <div class="bootom_line" v-if="index === tabIndex"></div>
            </div>
          </nav>
        </div>
        <div class="right_btn_box flex align text-14">
          <!-- <div class="btn_text">免费试用</div> -->
          <div class="btn_login" @click="cooperation">欢迎合作</div>
        </div>
        <div class="introduction_box flex between" v-show="show_introduction_box" @mouseover="show_introduction_box=true" @mouseleave="show_introduction_box=false">
          <div class="system_list_box" v-for="(item,index) in system_list" :key="index">
            <div class="system_list_item" v-for="ev in item" :key="ev.name" :class="ev.isTitle?'system_title':''">{{ev.name}}</div>
          </div>
        </div>
      </div>
    </header>
    <el-dialog :append-to-body="true" :modal-append-to-body="false" title="联系我们" :visible.sync="dialogFlag" width="600px">
      <el-form ref="cooperationForm" :model="formData" inline label-position="right" label-width="90px" :rules="rules">
        <el-form-item label="留言内容" prop="content">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请在此处填写留言内容，我们会尽快与您联系。（必填）"
            v-model="formData.content"
            maxlength="300"
            show-word-limit
            style="width:400px;"
          />
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="formData.name" placeholder="请填写您的姓名。（选填）" style="width:400px" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone" >
          <el-input v-model="formData.phone" placeholder="请填写您的号码。（必填）" style="width:400px" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="formData.address" placeholder="请填写您的地址。（选填）" style="width:400px" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="微信">
          <el-input v-model="formData.wechat" placeholder="请填写您的微信号。（选填）" style="width:400px" maxlength="30" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFlag = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="submit" style="background:#FF163A;border-color:#FF163A;">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveContact } from '@/api/common'
export default {
  name: 'CompHead',
  props: {
    logoColor: {
      type: Boolean,
      default: false,
    },
    navIndex: {
      type: Number,
      default: 0,
    },
    bgColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      navList: [{ name: '首页', path:'/' }, { name: '产品介绍', path:'', right_icon:require('../../assets/img/head/head_jiantou.png') }, { name: '关于我们', path:'my' }, { name: '合作品牌', path:'cooperation' }],
      tabIndex: 0,
      show_introduction_box: false,
      system_list: [
        [
          {name:'享库管家Saas系统',path:'',isTitle:true},
          {name:'POI管理',path:''},
          {name:'团购电商管理',path:''},
          {name:'享库外卖',path:''},
          {name:'在线买单',path:''},
          {name:'数据分析',path:''},
        ],
        [
          {name:'惠本地分销系统',path:'',isTitle:true},
          {name:'电商小程序',path:''},
          {name:'串货云市场',path:''},
          {name:'智能分账',path:''},
        ],
        [
          {name:'享库探店团系统',path:'',isTitle:true},
          {name:'达人大数据匹配',path:''},
          {name:'自动视频剪辑',path:''},
          {name:'达人自动撮合匹配',path:''},
          {name:'账号代运营',path:''},
        ],
        [
          {name:'享来客私域系统',path:'',isTitle:true},
          {name:'智能会员管理',path:''},
          {name:'智能社群管理',path:''},
          {name:'营销活动插件',path:''},
        ],
        
      ],

      dialogFlag: false,
      formData:{
        content:'',
        name:'',
        phone:'',
        address:'',
        wechat:'',
      },
      rules: {
        content: [{ required: true, message: '请填写留言内容', trigger: 'change' }],
        phone: [{ required: true, message: '请填写您的号码', trigger: 'change' }],
      },
      loading: false
    }
  },
  mounted() {
    this.tabIndex = this.navIndex
  },
  methods: {
    clickLogo(){
      if ('/' == this.$route.name) return '';
      this.$router.push('/')
    },
    clickNavTab(i, e) {
      this.tabIndex = i
      if(!e)return '';
      if (e == this.$route.name) return '';
      this.$router.push(e)
    },
    cooperation(){
      this.formData = {
        content:'',
        name:'',
        phone:'',
        address:'',
        wechat:'',
      }
      this.dialogFlag = true
    },
    async submit(){
      await this.$refs.cooperationForm.validate()
      this.loading = true
      saveContact(this.formData)
        .then(res => {
          console.log("res", res)
          this.loading = false
          this.$message.success('留言成功')
          this.dialogFlag = false
        })
        .catch(() => {
          this.loading = false
        })
    } 
  },
}
</script>
<style lang="less" scoped>
.compHead {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  .head-box {
    position: relative;
    max-width: 1240px;
    margin: 0 auto;
    box-sizing: border-box;
    .img {
      width: 1.37rem;
      height: 0.44rem;
      overflow: hidden;
      cursor: pointer;
      > img {
        width: 100%;
      }
    }
    .nav {
      justify-content: flex-start;
      .item {
        padding: 0.2rem 0;
        margin-left: 0.4rem;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.85);
        position: relative;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: 0px;
        .bootom_line{
          position: absolute;
          left:25%;
          top: 0.6rem;
          width: 50%;
          height: 3px;
          background: #FF163A;
        }
      }
    }
    .right_btn_box{
      .btn_text{
        color: #FF163A;
        letter-spacing: 0px;
        cursor: pointer;
      }
      .btn_login{
        cursor: pointer;
        margin-left:24px;
        width: 98px;
        height: 32px;
        padding: 5px 20px;
        border-radius: 4px;
        background: #FF163A;
        box-shadow: 0px 0.01px 0.02px 0px rgba(255, 22, 58, 0.25);
        color: #fff;
        box-sizing: border-box;
      }
    }
    .introduction_box{
      transition: all 3s;
      position: absolute;
      top: 60px;
      width: 632px;
      min-height: 242px;
      display: flex;
      padding: 24px 40px;
      border-radius: 4px;
      background: #FFFFFF;
      /* 块面投影 */
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      .system_list_box{
        z-index: 999;
        text-align: left;
        .system_list_item{
          cursor: pointer;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          font-weight: 0;
          line-height: 18px;
          letter-spacing: 0em;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 24px;
        }
        .system_title{
          height: 40px;
          margin-top: 0;
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          letter-spacing: 0em;
          color: rgba(0, 0, 0, 0.85);
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}
.compHead.bgColor {
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
</style>