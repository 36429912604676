/*
 * @Date: 2020-12-29 11:32:49
 * @LastEditTime: 2021-02-01 13:50:18
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: 页面路由
 * @FilePath: \portal\src\router\routes\web.js
 */
export default [{
    name:'/',
    path: "/",
    meta: {
        title: "首页"
    },
    component: () => import( /* webpackChunkName: "web" */ "@/views/home/index.vue")
}, {
    name:'community',
    path: "/community",
    meta: {
        title: "社区精选"
    },
    component: () => import( /* webpackChunkName: "web" */ "@/views/community/index.vue")
}, {
    name:'my',
    path: "/my",
    meta: {
        title: "关于我们"
    },
    component: () => import( /* webpackChunkName: "web" */ "@/views/my/index.vue")
}, {
    name:'cooperation',
    path: "/cooperation",
    meta: {
        title: "合作伙伴"
    },
    component: () => import( /* webpackChunkName: "web" */ "@/views/cooperation/index.vue")
}, {
    name:'404',
    path: "/404",
    meta: {
        title: "404"
    },
    component: () => import( /* webpackChunkName: "web" */ "@/views/common/404.vue")
}, {
    name:'Demo',
    path: "/Demo",
    meta: {
        title: "Demo"
    },
    component: () => import( /* webpackChunkName: "web" */ "@/views/common/demo.vue")
}, {
    path: "*",
    redirect: "/404"
}]